<template>
  <div>
    <div>
      <legend class="text-lg font-medium text-gray-900">
        {{ this.getQuestion }}
      </legend>
      <div class="relative mt-4">
        <div
          class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="date"
          :id="`date-${this.question.id}`"
          v-model="this.date"
          :max="new Date().toISOString().split('T')[0]"
          class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
        />
      </div>
      <p v-if="!this.validResponse" class="my-2 text-sm text-red-600">
        {{ $t("Please enter a valid date") }}
      </p>
    </div>
    <FormNavigationButton
      :isFirstStep="this.isFirstStep"
      @next="next"
      @previous="previous"
    />
  </div>
</template>

<script>
import FormNavigationButton from "@/views/customer/claim/form/navigation/FormNavigationButton";
import { getTranslatedQuestionIfNeeded } from "@/views/customer/claim/form/utils/translation-functions";

export default {
  components: {
    FormNavigationButton,
  },

  props: [
    "question",
    "possibleResponses",
    "isFirstStep",
    "currentResponse",
    "startDate",
  ],

  data() {
    return {
      date: this.currentResponse,
      validResponse: true,
    };
  },

  computed: {
    isValid() {
      return (
        this.date !== null &&
        new Date(this.date) >=
          new Date(
            this.startDate.getFullYear(),
            this.startDate.getMonth(),
            this.startDate.getDate()
          ) &&
        new Date(this.date) < new Date()
      );
    },
    getSingleResponseId() {
      return this.possibleResponses[0].id;
    },
    getQuestion() {
      return getTranslatedQuestionIfNeeded(this.question, this.$i18n.locale);
    },
  },

  methods: {
    next: function () {
      if (this.isValid) {
        this.validResponse = true;
        this.$emit("next", this.date, this.getSingleResponseId);
      } else {
        this.validResponse = false;
      }
    },

    previous: function () {
      this.$emit("previous");
    },
  },
};
</script>
