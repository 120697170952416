<template>
  <div>
    <div
      class="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-5 mb-4 sm:mt-6"
    >
      <div class="flex flex-row items-center">
        <ExclamationIcon class="w-8 h-8 text-yellow-400" />
        <p class="ml-4 text-yellow-700">
          Une action est requise de votre part.
        </p>
      </div>
      <div class="pl-12">
        <a
          v-bind:href="this.paymentLink"
          class="text-yellow-700 font-semibold underline"
          target="_blank"
        >
          {{ $t("Procéder au paiement de la franchise") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ExclamationIcon,
  },

  props: ["paymentLink"],
};
</script>
