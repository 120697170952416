<template>
  <div class="pt-36 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-3xl">
      <div class="flex flex-col items-center gap-4">
        <CheckCircleIcon class="h-16 w-16 text-primary-dark" />
        <p class="text-xl text-center font-bold">Votre assurance est activée</p>
        <p class="text-md text-center">
          Retrouvez toutes les informations concernant votre assurance sur votre
          adresse e-mail.
        </p>
        <p class="text-center">Une question relative à votre assurance ?</p>
        <a href="mailto:contact@estaly.co" class="underline text-center"
          >contact@estaly.co</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/outline";
export default {
  components: {
    CheckCircleIcon,
  },
};
</script>
