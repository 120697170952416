<template>
  <div v-if="this.marketplace">
    <div class="mb-4">
      <label
        for="productCategory"
        class="block text-sm font-medium"
        :style="{ color: textColor }"
        >{{ $t("Contract category") }}</label
      >
      <select
        id="productCategory"
        v-model="this.productCategory"
        name="productCategory"
        class="shadow-sm bg-transparent block w-full sm:text-sm rounded-3xl focus:ring-0 focus:border-current placeholder-gray-400"
        :style="{ color: textColor }"
      >
        <option
          v-for="category in this.categories"
          v-bind:key="category.name"
          :value="category.value"
        >
          {{ $t(category.name) }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: ["marketplace", "categories", "textColor"],

  watch: {
    productCategory: function (newProductCategory) {
      this.$emit("selectProductCategory", newProductCategory);
    },
  },

  mounted() {
    this.productCategory = this.categories[0].value;
  },

  data() {
    return {
      productCategory: "",
    };
  },
};
</script>
