import { createStore } from "vuex";
import auth from "./modules/auth";
import notifications from "./modules/notifications";
import store from "./modules/store";
import customer from "./modules/customer";
import navigation from "./modules/navigation";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  modules: {
    auth,
    notifications,
    store,
    customer,
    navigation,
  },
  plugins: [vuexLocal.plugin],
});
