<template>
  <div class="bg-gray shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ $t("Claim Information") }}
      </h3>
    </div>
    <div class="border-t border-gray-200">
      <dl>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">
            {{ $t("First Name") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <p>{{ claim.customerFirstName }}</p>
          </dd>
        </div>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">
            {{ $t("Last Name") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <p>{{ claim.customerLastName }}</p>
          </dd>
        </div>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">Adresse e-mail</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <p>{{ claim.customerEmail }}</p>
          </dd>
        </div>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">Nom du produit</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <p>{{ claim.productTitle }}</p>
          </dd>
        </div>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">Nom de la boutique</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <p>{{ claim.salesChannel }}</p>
          </dd>
        </div>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">Date de déclaration</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <p>{{ this.getClaimFormattedFiledDate }}</p>
          </dd>
        </div>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">
            Statut de traitement
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <span
              v-bind:class="[
                getClaimStatusBannerStyle(claim.status.color),
                'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium',
              ]"
            >
              {{ claim.status.text }}
            </span>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/date_formatter";

export default {
  props: ["claim"],
  computed: {
    getClaimFormattedFiledDate() {
      return formatDate(this.claim.filedDate, this.$i18n.locale);
    },
  },
  methods: {
    getClaimStatusBannerStyle: function (color) {
      return `bg-${color}-100 text-${color}-800`;
    },
  },
};
</script>
