<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <router-link to="/plans" class="ml-1 text-sm font-medium text-primary">
      <div class="flex items-center">
        <ChevronLeftIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
        Mes contrats
      </div>
    </router-link>
    <InsuranceInformation class="mt-6" v-bind:plan="this.plan" />
    <InsuredProductsInformation
      class="mt-6"
      v-bind:plan="this.plan"
      @refreshPlanSale="refreshPlanSale"
    />
    <PlanSaleDocuments class="mt-6" v-bind:plan="this.plan" />
    <CustomerClaimList class="my-6" v-bind:claims="this.pastClaims" />
  </div>
</template>

<script>
import { ChevronLeftIcon } from "@heroicons/vue/solid";
import CustomerService from "../api/services/customer";

const pages = [
  { name: "Plans", to: "/plans", current: false },
  { name: "Looker Headphones", to: "#", current: true },
];

import { mapState, mapMutations, mapActions } from "vuex";
import CustomerClaimList from "@/views/customer/claim/list/CustomerClaimList";
import PlanSaleDocuments from "@/components/plan/PlanSaleDocuments.vue";
import InsuranceInformation from "@/views/plan/InsuranceInformation.vue";
import InsuredProductsInformation from "@/views/plan/InsuredProductsInformation.vue";
import { isObjectValid } from "@/utils/validation";

export default {
  components: {
    InsuredProductsInformation,
    InsuranceInformation,
    PlanSaleDocuments,
    ChevronLeftIcon,
    CustomerClaimList,
  },

  created() {
    this.setPlan();
    this.getClaims();
  },

  data() {
    return {
      plan: {},
      pastClaims: [],
      pages: pages,
      isSelectorOpen: false,
      category: "",
    };
  },

  computed: {
    ...mapState("customer", ["plans"]),
    contractDetailsLink() {
      return this.plan.marketingPage;
    },
  },

  watch: {
    "$route.params.id": function (new_value) {
      if (isObjectValid(new_value)) {
        this.setPlan();
      }
    },
  },

  methods: {
    ...mapMutations("store", ["setIsLoading"]),
    ...mapActions("customer", ["getPlans"]),

    setPlan: function () {
      this.plan = this.plans.filter(
        (plan) => plan.id === parseInt(this.$route.params.id)
      )[0];
      this.getClaims();
    },

    getClaims: async function () {
      try {
        this.setIsLoading(true);
        const response = await CustomerService.getClaimsByPlanSaleId(
          this.plan.id
        );
        this.pastClaims = response.data.claims;
        this.setIsLoading(false);
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 422:
            this.notify({
              category: "simple",
              type: "error",
              title: "Something went wrong.",
              text: error.response.data.error,
            });
        }
      }
    },

    refreshPlanSale: async function () {
      await this.getPlans();
      this.setPlan();
    },
  },
};
</script>
