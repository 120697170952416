<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="">
    <div class="py-5">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ $t("Claim Form Summary") }}
      </h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">
        {{ $t("Questions and responses") }}
      </p>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="sm:divide-y sm:divide-gray-200">
        <div
          v-for="formResponse in this.responses"
          v-bind:key="formResponse.id"
        >
          <div class="py-2">
            <dt class="text-sm font-medium text-gray-500 mb-2">
              {{ formResponse.question }}
            </dt>
            <dd
              v-if="
                !isQuestionType(formResponse, 'file') &&
                !isQuestionType(formResponse, 'checkbox')
              "
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
            >
              {{ formResponse.response }}
            </dd>
            <dd
              v-if="isQuestionType(formResponse, 'checkbox')"
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
            >
              {{ formResponse.response.content }}
            </dd>
            <dd
              v-if="isQuestionType(formResponse, 'file')"
              class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
            >
              <ul
                role="list"
                class="border border-gray-200 rounded-md divide-y divide-gray-200"
              >
                <li
                  v-for="file in formResponse.response"
                  v-bind:key="file"
                  class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                >
                  <div class="w-0 flex-1 flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
                      />
                    </svg>
                    <span class="ml-2 flex-1 w-0 truncate">{{
                      file.name
                    }}</span>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: ["responses"],

  methods: {
    isQuestionType: function (response, type) {
      return response.type === type;
    },
  },
};
</script>
