export function isStringValid(input) {
  return input !== undefined && input !== null && input !== "";
}

export function isObjectValid(input) {
  return input !== undefined && input !== null;
}

export function isArrayEmpty(input) {
  return isObjectValid(input) && input.length === 0;
}

export function isArrayNotEmpty(input) {
  return !isArrayEmpty(input);
}
