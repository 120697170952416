import { createRouter, createWebHistory } from "vue-router";
import Signin from "../views/Signin.vue";
import Signup from "../views/Signup.vue";
import Profile from "../views/Profile.vue";

import Plans from "../views/Plans.vue";
import Plan from "../views/Plan.vue";
import FileAClaim from "../views/FileAClaim.vue";
import PasswordReset from "../views/PasswordReset.vue";
import Subscription from "../views/Subscription.vue";
import ContractsDetails from "../views/ContractsDetails.vue";
import FileAClaimPublic from "../views/FileAClaimPublic.vue";
import ClaimFormPublic from "../views/ClaimFormPublic.vue";
import SubscriptionPublic from "../views/SubscriptionPublic.vue";
import Cancellation from "../views/customer/Cancellation.vue";

import store from "../store";
import ClaimFormPrivate from "@/views/customer/claim/form/ClaimFormPrivate";
import CustomerClaimDetails from "@/views/customer/claim/details/CustomerClaimDetails";
import SubscriptionSuccess from "../views/SubscriptionSuccess.vue";
import CustomerClaims from "@/views/customer/claim/list/CustomerClaims.vue";
import Invoices from "@/views/customer/Invoices.vue";

const publicRoutes = [
  {
    path: "/signin",
    component: Signin,
  },

  {
    path: "/signup",
    component: Signup,
  },

  {
    path: "/password-reset/:id",
    component: PasswordReset,
  },

  {
    path: "/subscription/:uniqueToken",
    component: Subscription,
  },

  {
    path: "/contracts-details",
    component: ContractsDetails,
  },
  {
    path: "/file-a-claim",
    component: FileAClaimPublic,
  },

  {
    path: "/file-a-claim/:id/:date",
    component: ClaimFormPublic,
  },

  {
    path: "/:storeShop/subscription",
    component: SubscriptionPublic,
  },

  {
    path: "/subscription/success",
    component: SubscriptionSuccess,
  },
];

const customerRoutes = [
  {
    path: "/plans",
    component: Plans,
    meta: { layout: "customer-layout", navigation: "Contracts" },
  },

  {
    path: "/plans/:id",
    component: Plan,
    meta: { layout: "customer-layout", navigation: "Contracts" },
  },

  {
    path: "/customer/claims",
    component: FileAClaim,
    meta: { layout: "customer-layout", navigation: "File a claim" },
  },

  {
    path: "/customer/claims/list",
    component: CustomerClaims,
    meta: { layout: "customer-layout", navigation: "Claim List" },
  },

  {
    path: "/customer/claims/:id",
    component: CustomerClaimDetails,
    meta: { layout: "customer-layout", navigation: "Claim List" },
  },

  {
    path: "/plans/:id/claim",
    component: FileAClaim,
    meta: { layout: "customer-layout", navigation: "Claim List" },
  },

  {
    path: "/plans/:id/cancellation",
    component: Cancellation,
    meta: { layout: "customer-layout", navigation: "Plans" },
  },

  {
    path: "/plans/:id/claim_form/:claimProductId",
    component: ClaimFormPrivate,
    meta: { layout: "customer-layout", navigation: "File a claim" },
  },

  {
    path: "/account",
    component: Profile,
    meta: { layout: "customer-layout", navigation: "Account" },
  },

  {
    path: "/customer/invoices",
    component: Invoices,
    meta: { layout: "customer-layout", navigation: "Invoices" }
  }
];

function needsHttpsRedirection() {
  return (process.env.NODE_ENV === "production" ||
    process.env.NODE_ENV === "demo" ||
    process.env.NODE_ENV === "staging") &&
    location.protocol !== "https:";
}

function redirectHttps() {
  location.replace(
    `https:${location.href.substring(location.protocol.length)}`
  );
}

const routes = publicRoutes.concat(customerRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (needsHttpsRedirection()) {
    redirectHttps();
  } else {
    const publicPages = publicRoutes.map((route) => route.path);

    const authRequired = !to.matched.some((matched) =>
      publicPages.includes(matched.path)
    );

    const signedIn = store.state.auth.signedIn;

    if (authRequired && !signedIn) {
      next("/signin");
    } else if (signedIn) {
      if (to.path === "/") {
        if (store.getters["auth/isCustomer"]) {
          next("/plans");
        } else {
          next("/signin");
        }
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

router;

export default router;
