<template>
  <CustomerClaimList v-bind:claims="this.claims" />
</template>
<script>
import CustomerService from "@/api/services/customer";
import CustomerClaimList from "@/views/customer/claim/list/CustomerClaimList.vue";

export default {
  components: {
    CustomerClaimList,
  },

  data() {
    return {
      claims: [],
    };
  },

  created() {
    this.getClaims();
  },

  methods: {
    getClaims: async function () {
      try {
        const response = await CustomerService.getClaims();
        this.claims = response.data.claims;
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 422:
            this.notify({
              category: "simple",
              type: "error",
              title: "Something went wrong.",
              text: error.response.data.error,
            });
        }
      }
    },
  },
};
</script>
