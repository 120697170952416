<template>
  <div>
    <div :class="[this.containerClasses, 'border-l-4 p-4 mt-5 mb-4 sm:mt-6']">
      <div class="flex flex-row items-center">
        <ExclamationIcon :class="[this.iconClasses, 'w-8 h-8']" />
        <p class="ml-4">Une action est requise de votre part :</p>
      </div>
      <div class="pl-12">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ExclamationIcon,
  },

  props: {
    errorLevel: {
      type: String,
      default: "error",
    },
  },

  data() {
    return {};
  },

  computed: {
    containerClasses() {
      let classes = "";
      switch (this.errorLevel) {
        case "error":
          classes = "bg-red-50 border-red-400 text-red-700";
          break;
        case "warning":
          classes = "bg-yellow-50 border-yellow-400 text-yellow-700";
          break;
      }

      return classes;
    },
    iconClasses() {
      let classes = "";
      switch (this.errorLevel) {
        case "error":
          classes = "text-red-400";
          break;
        case "warning":
          classes = "text-yellow-400";
          break;
      }

      return classes;
    },
  },
};
</script>
