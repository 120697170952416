<template>
  <div>
    <legend class="text-lg font-medium text-gray-900">
      {{ this.getQuestion }}
    </legend>
    <div class="relative mt-4">
      <GoogleAddress @newAddress="this.newAddress" />
    </div>
    <p v-if="!this.validResponse" class="my-2 text-sm text-red-600">
      {{ $t("Please enter a valid address") }}
    </p>
  </div>
  <FormNavigationButton
    :isFirstStep="this.isFirstStep"
    @next="next"
    @previous="previous"
  />
</template>

<script>
import FormNavigationButton from "@/views/customer/claim/form/navigation/FormNavigationButton.vue";
import { getTranslatedQuestionIfNeeded } from "@/views/customer/claim/form/utils/translation-functions";
import { isObjectValid } from "@/utils/validation";
import GoogleAddress from "@/components/GoogleAddress.vue";

export default {
  components: {
    GoogleAddress,
    FormNavigationButton,
  },

  computed: {
    isValid: function () {
      return isObjectValid(this.address);
    },
    getQuestion() {
      return getTranslatedQuestionIfNeeded(this.question, this.$i18n.locale);
    },
    getSingleResponseId() {
      return this.possibleResponses[0].id;
    },
  },

  data() {
    return {
      address: this.currentResponse,
      validResponse: true,
    };
  },

  props: [
    "question",
    "possibleResponses",
    "isFirstStep",
    "currentResponse",
    "startDate",
  ],

  methods: {
    next: function () {
      if (this.isValid) {
        this.validResponse = true;
        this.$emit("next", this.date, this.getSingleResponseId);
      } else {
        this.validResponse = false;
      }
    },

    previous: function () {
      this.$emit("previous");
    },

    newAddress: function (newAdress) {
      this.address = newAdress;
    },
  },
};
</script>
