<template>
  <div>
    <div class="flex flex-col justify-between mt-4">
      <div class="flex-grow">
        <div>
          <div class="ml-3 text-sm">
            <p class="text-center text-gray-500">
              {{
                $t(
                  "I agree to subscribe to the Theft and Breakage Insurance and I confirm that I have read, accepted and saved"
                )
              }}
              <a
                :href="contractDetailsLink"
                target="_blank"
                class="font-medium text-primary"
                >{{ $t("the information notice") }}</a
              >
              {{ $t("as general conditions and") }}
              <a
                :href="contractDetailsLink"
                target="_blank"
                class="font-medium text-primary"
                >{{ $t("the information document") }}</a
              >
              {{
                $t(
                  "on the insurance product. I agree to receive the contractual information electronically and save it on a durable support."
                )
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    contractDetailsLink: String,
  },
};
</script>
