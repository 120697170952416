<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <div>
      <h1
        class="text-4xl font-heading-1 pb-4 pl-2 font-extrabold tracking-tight text-gray-900"
      >
        {{ $t("Your protection plans") }}
      </h1>
    </div>
    <div>
      <ErrorBanner v-if="aPlanIsSuspended" error-level="error">
        {{
          $t(
            "One of your contracts has been suspended, please settle your situation from the Invoices tab"
          )
        }}
      </ErrorBanner>
      <ErrorBanner v-else-if="!allPaymentsUpToDate" error-level="error">
        {{
          $t(
            "You are not up to date with your payments, please settle your situation from the Invoices tab"
          )
        }}
      </ErrorBanner>
    </div>
    <ul
      role="list"
      class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      <li
        v-for="(plan, id) in plans"
        :key="id"
        v-bind:class="[
          plan.status === 'Active' ? 'bg-white' : 'bg-slate-50 opacity-70',
          'col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200',
        ]"
      >
        <div class="flex-1 flex flex-col p-8">
          <img
            class="max-w-32 max-h-32 object-contain flex-shrink-0 mx-auto"
            :src="plan.imageUrl"
            alt=""
          />
          <dl class="mt-6 flex-grow flex flex-col justify-between">
            <dt class="sr-only">{{ $t("Expires on") }}</dt>
            <dd class="text-gray-500 text-xs">{{ plan.vendor }}</dd>
          </dl>
          <h3 class="mt-1 text-gray-900 text-sm font-medium">
            {{ plan.name }}
          </h3>
          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dt class="sr-only">{{ $t("Expires on") }}</dt>
            <dd class="text-gray-500 text-sm">
              {{ $t("Expires on") }} {{ plan.expiresOn }}
            </dd>
            <div v-if="!plan.paymentsUpToDate">
              <dd class="mt-3">
                <ExclamationIcon
                  class="text-red-400 w-8 h-8 mx-auto"
                  :aria-label="$t('Plan awaiting payment')"
                />
              </dd>
            </div>
            <dt class="sr-only">{{ $t("Status") }}</dt>
            <dd class="mt-3">
              <span
                v-bind:class="[
                  plan.status === 'Active'
                    ? 'text-green-800 bg-green-100'
                    : 'text-gray-800 bg-gray-100',
                  'px-2 py-1 text-xs font-medium rounded-full',
                ]"
                >{{ $t(plan.status) }}</span
              >
            </dd>
          </dl>
        </div>
        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="cursor-pointer w-0 flex-1 flex">
              <router-link
                v-bind:to="`/plans/${plan.id}`"
                class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
              >
                <DocumentTextIcon
                  class="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
                <span class="ml-3">{{ $t("View plan") }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { DocumentTextIcon, ExclamationIcon } from "@heroicons/vue/solid";
import { mapActions, mapState } from "vuex";
import ErrorBanner from "@/components/utils/ErrorBanner.vue";

export default {
  components: {
    ErrorBanner,
    ExclamationIcon,
    DocumentTextIcon,
  },

  computed: {
    ...mapState("auth", ["signedIn"]),
    ...mapState("customer", ["plans"]),
    allPaymentsUpToDate() {
      return this.plans.every((plan) => plan.paymentsUpToDate);
    },
    aPlanIsSuspended() {
      return this.plans.some((plan) => plan.status === "Suspended");
    }
  },

  created() {
    if (this.signedIn) {
      this.getPlans();
    } else {
      this.$router.push("/signin");
    }
  },

  methods: {
    ...mapActions("customer", ["getPlans"]),
  },
};
</script>
