<template>
  <div></div>
</template>

<script>
export default {
  beforeMount() {
    this.redirect();
  },
  methods: {
    redirect() {
      window.location.replace("https://bike.estaly.co");
    },
  },
};
</script>
