<template>
  <div class="bg-primary-light p-2">
    <div class="flex justify-center items-center">
      <div>
        <img class="h-8 mr-6" src="@/assets/care.png" />
      </div>
      <div>
        <p class="px-4 text-xl text-gray-900 font-heading-1 tracking-tight font-bold">
          {{ this.discount.marketing }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    discount: Object,
  },
};
</script>
