<template>
  <div v-if="offerPrice">
    <p class="text-2xl font-bold pb-8">Votre moyen de paiement</p>
    <form id="payment-form">
      <div id="payment-element"></div>
      <p class="mt-1 text-xs text-red-400">
        Pour les paiements en SEPA, la confirmation du paiement prend en moyenne
        entre 3 et 5 jours.
      </p>
      <div class="flex justify-center">
        <button
          type="submit"
          id="submit"
          class="mt-8 inline-flex items-center rounded-2xl border border-transparent px-6 py-3 text-base shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 text-button-font-primary bg-primary uppercase"
        >
          <div class="hidden" id="loading">
            <svg
              class="motion-reduce:hidden animate-spin h-5 w-5 text-white-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
          <div class="spinner hidden" id="spinner"></div>
          <div v-if="isPromotionOnMonths">
            <div v-if="isOffered">
              <span id="button-text"
                >Offert pendant {{ lead.discount.offeredMonths }} mois*</span
              >
            </div>
            <div v-else>
              <span id="button-text"
                >Payer {{ lead.discount.finalPrice }}€ pendant
                {{ lead.discount.offeredMonths }} mois</span
              >
            </div>
          </div>
          <div v-else>
            <span id="button-text">Payer {{ offerPrice.price }}€</span>
          </div>
        </button>
      </div>
      <div class="flex justify-center" v-if="isPromotionOnMonths">
        <span class="mt-1 text-gray-500 text-sm"
          >* puis {{ this.offerPrice.price }}€/mois</span
        >
      </div>
      <div id="payment-message" class="hidden"></div>
      <div
        v-if="isPromotionOnMonths && isOffered"
        class="flex flex-col items-center mt-1 text-xs font-semibold"
      >
        <p class="text-center">
          Dès que vous ajoutez votre moyen de paiement, le contrat d'assurance
          est activé et vous êtes assuré.
        </p>
        <p>
          Vous ne serez prélevé qu'à la fin {{ offeredMonthsPaiementStart() }}.
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import LeadService from "../api/services/lead";
import { isObjectValid } from "@/utils/validation";
import { mapActions } from "vuex";
export default {
  props: {
    offerPrice: Object,
    lead: Object,
  },
  computed: {
    isPromotionOnMonths() {
      if (isObjectValid(this.lead.discount.type)) {
        return (
          this.lead.discount.type.split("_")[0] === "months" &&
          this.offerPrice.recurring
        );
      } else {
        return false;
      }
    },
    isOffered() {
      if (isObjectValid(this.lead.discount.type)) {
        return (
          this.lead.discount.type === "months_offered" &&
          this.offerPrice.recurring
        );
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      stripeKey: process.env.VUE_APP_STRIPE_KEY,
    };
  },
  watch: {
    offerPrice: function () {
      this.initialize();
      this.checkStatus();
      document
        .querySelector("#payment-form")
        .addEventListener("submit", this.handleSubmit);
      this.emailAddress = "";
    },
  },
  async mounted() {
    this.stripe = await loadStripe(this.stripeKey, { locale: "fr" });
    this.initialize();
    this.checkStatus();
    document
      .querySelector("#payment-form")
      .addEventListener("submit", this.handleSubmit);
    this.emailAddress = "";
  },
  methods: {
    ...mapActions("notifications", ["notify"]),
    async initialize() {
      const clientSecret = this.offerPrice.stripeClient;
      const appearance = {
        theme: "stripe",
        variables: {
          borderRadius: "1rem",
          fontSizeSm: "14px",
          fontLineHeight: "10px",
          spacingUnit: "3px",
          spacingGridColumn: "1.5rem",
          fontSizeBase: "0.875rem",
        },
        rules: {
          ".Input": {
            border: "1px solid #9ca3af",
          },
          ".Input::placeholder": {
            "font-size": "0.875rem",
          },
        },
      };

      this.elements = this.stripe.elements({ appearance, clientSecret });
      const paymentElementOptions = {
        layout: "tabs",
      };
      const paymentElement = this.elements.create(
        "payment",
        paymentElementOptions
      );
      paymentElement.mount("#payment-element");
    },
    async handleSubmit(e) {
      const validation = this.validateLeadInformations(this.lead);
      e.preventDefault();
      this.setLoading(true);
      if (validation[0]) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Something went wrong.",
          text: validation[1],
        });
      } else {
        this.updateLeadInformation();
        const elements = this.elements;
        let { error } = "";
        if (
          this.lead.discount.type === "months_offered" &&
          this.offerPrice.recurring
        ) {
          error = await this.stripe.confirmSetup({
            elements,
            confirmParams: {
              setup_intent: this.setup_intent_client_secret,
              return_url: window.location.origin + "/subscription/success",
            },
          });
        } else {
          error = await this.stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: window.location.origin + "/subscription/success",
              receipt_email: this.emailAddress,
            },
          });
        }
        console.log(error);
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          this.showMessage(error.message);
        } else {
          this.showMessage("An unexpected error occurred.");
        }
      }
      this.setLoading(false);
    },
    async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );
      if (!clientSecret) {
        return;
      }
      const { paymentIntent } = await this.stripe.retrievePaymentIntent(
        clientSecret
      );
      switch (paymentIntent.status) {
        case "succeeded":
          this.showMessage("Payment succeeded!");
          break;
        case "processing":
          this.showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          this.showMessage(
            "Your payment was not successful, please try again."
          );
          break;
        default:
          this.showMessage("Something went wrong.");
          break;
      }
    },
    offeredMonthsPaiementStart() {
      return this.lead.discount.offeredMonths === 1
        ? "du premier mois"
        : "des " + this.lead.discount.offeredMonths + " mois";
    },
    showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");
      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;
      setTimeout(function () {
        messageContainer.classList.add("hidden");
        messageText.textContent = "";
      }, 4000);
    },
    setLoading(isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("#submit").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
        document.querySelector("#loading").classList.remove("hidden");
      } else {
        document.querySelector("#submit").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
        document.querySelector("#loading").classList.add("hidden");
      }
    },
    validateLeadInformations(lead) {
      if (!lead.firstName) {
        return [true, "First name is missing"];
      } else if (!lead.lastName) {
        return [true, "Last name is missing"];
      } else if (!lead.email) {
        return [true, "Email is missing"];
      } else if (!lead.birthDate) {
        return [true, "Birth date is missing"];
      } else if (!lead.address) {
        return [true, "Address is missing"];
      } else if (!lead.phone) {
        return [true, "Phone is missing"];
      }
      return [false, "Ok"];
    },

    async updateLeadInformation() {
      const leadParams = {
        lead: {
          unique_token: this.$route.params.uniqueToken,
          customer: {
            email: this.lead.email,
            phone: this.lead.phone,
            first_name: this.lead.firstName,
            last_name: this.lead.lastName,
            birth_date: this.lead.birthDate,
            birth_city: this.lead.birthCity,
            birth_country: this.lead.birthCountry,
          },
          address: {
            address1: this.lead.address.street,
            address2: this.lead.address.other,
            city: this.lead.address.city,
            province: this.lead.address.province,
            country: this.lead.address.country,
            zip_code: this.lead.address.zipCode,
          },
        },
      };
      try {
        await LeadService.update(leadParams);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
