import { plainAxiosInstance, securedAxiosInstance } from "../axios";

class LeadService {
  create(params) {
    return securedAxiosInstance.post(`/api/v1/leads`, params);
  }

  createPublicLead(params) {
    return plainAxiosInstance.post(`/leads_creation`, params);
  }

  getMarketingDetails(productCategory, storeShopName) {
    return plainAxiosInstance.get(
      `public/stores/marketings?category=${productCategory}&store_shop_name=${storeShopName}`
    );
  }

  getStore(storeShopName) {
    return plainAxiosInstance.get(`/public/stores/${storeShopName}`);
  }

  getInformations(uniqueToken) {
    return plainAxiosInstance.get(`/leads` + `?uniqueToken=${uniqueToken}`);
  }

  redirectToPayment(uniqueToken, contractId, productId) {
    return plainAxiosInstance.get(
      `/leads_payment` +
        `?uniqueToken=${uniqueToken}` +
        `&contractId=${contractId}` +
        `&productId=${productId}`
    );
  }

  update(leadParams) {
    return plainAxiosInstance.put("/leads_update", leadParams)
  }
}

export default new LeadService();
