const SIGNIN_URL = "/user/sessions";
const SIGNUP_URL = "/user/registrations";
const NEW_PASSWORD_URL = "/user/passwords";
const CLAIM_URL = "/claim_form";

const CUSTOMER_PLAN_SALES_URL = "/customer/plan_sales";

export {
  SIGNIN_URL,
  SIGNUP_URL,
  NEW_PASSWORD_URL,
  CUSTOMER_PLAN_SALES_URL,
  CLAIM_URL,
};
