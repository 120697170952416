<template>
  <div>
    <div>
      <h1
        class="text-4xl font-heading-1 pb-4 pl-2 font-extrabold tracking-tight text-gray-900"
      >
        {{ $t("File a claim") }}
      </h1>
    </div>
    <div class="bg-white shadow overflow-hidden">
      <div class="px-4 pb-4 pt-2 flex items-center sm:px-6">
        <div v-if="activePlanSales.length == 0">
          <p class="mt-2 block text-sm font-medium text-red-700">
            Vous n'avez pas de contract d'assurance actif
          </p>
        </div>
        <VeeForm
          v-slot="{ handleSubmit }"
          v-bind:validation-schema="schema"
          as="div"
          class="w-full"
          v-if="activePlanSales.length > 0"
        >
          <form
            class="space-y-8 divide-y divide-gray-200 w-full"
            @submit="handleSubmit($event, handleCreateClaim)"
          >
            <div class="space-y-8 divide-y divide-gray-200 w-full">
              <div>
                <div class="mt-6 grid grid-cols-1 gap-y-8">
                  <div class="sm:max-w-lg">
                    <label
                      for="country"
                      class="block text-sm font-medium text-gray-700"
                    >
                      {{ $t("Which product are you filing a claim for?") }}
                    </label>
                    <div class="mt-1">
                      <Field
                        name="product"
                        as="select"
                        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        v-model="selectedClaimProductKey"
                      >
                        <option
                          v-for="claimProduct in claimProducts"
                          :key="`${claimProduct.planId}-${claimProduct.id}`"
                          :value="`${claimProduct.planId}-${claimProduct.id}`"
                        >
                          {{ claimProduct.title }}
                        </option>
                      </Field>
                      <ErrorMessage
                        name="product"
                        class="mt-2 text-sm text-red-600"
                      />
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-5">
              <div class="flex justify-end">
                <router-link
                  class="ml-3 cursor-pointer inline-flex justify-center py-2 pr-4 pl-2 shadow-sm text-sm text-button-font-primary bg-primary uppercase"
                  v-bind:to="`/plans/${selectedPlanId}/claim_form/${selectedClaimProductId}`"
                >
                  <span class="ml-3">{{ $t("Start") }}</span>
                </router-link>
              </div>
            </div>
          </form>
        </VeeForm>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
  components: {
    Field,
    VeeForm,
    ErrorMessage,
  },
  data() {
    const schema = yup.object({
      product: yup.string()
        .required("Veuillez sélectionner un produit")
        .matches(
          /^[0-9]+-[0-9]+$/, 
          "Le format du produit sélectionné est invalide"
        )
    });
    return {
      selectedClaimProductKey: null,
      schema,
    };
  },
  computed: {
    ...mapState("customer", ["plans"]),
    ...mapState("auth", ["currentUser"]),

    claimProducts() {
      const activePlans = this.plansLocal.filter(plan => plan.status === "Active");
      
      return activePlans.reduce((acc, plan) => {
        const productsWithPlanId = plan.product.map(product => {
          return { ...product, planId: plan.id };
        });
        return acc.concat(productsWithPlanId);
      }, []);
    },
    activePlanSales: function () {
      return this.plansLocal.filter((planSale) => planSale.status == "Active");
    },
    selectedPlanId() {
      return this.selectedClaimProductKey.split("-")[0];
    },
    selectedClaimProductId() {
      return this.selectedClaimProductKey.split("-")[1];
    }
  },
  created() {
    if (this.$route.params.id) {
      this.plansLocal = this.plans.filter(
        (plan) => plan.id === parseInt(this.$route.params.id)
      );
    } else {
      this.plansLocal = this.plans;
    }
    const plan = this.plansLocal[0];
    const initialKey = `${plan.id}-${plan.product[0].id}`;
    this.selectedClaimProductKey = initialKey;
  },
};
</script>
