<template>
  <CancellationFirstStep
    v-if="this.step == 0"
    @next="next"
    @reason="selectedReason"
    @cancellationDate="getCancellationDate"
  />
  <CancellationSecondStep
    v-if="this.step == 1"
    @feedbackExperience="getFeedbackExperience"
    @feedbackMessage="getFeedbackMessage"
    @cancelPlan="cancelPlan"
    :reason="this.reason"
  />
</template>

<script>
import { mapActions } from "vuex";
import CancellationFirstStep from "../../components/cancellation/CancellationFirstStep.vue";
import CancellationSecondStep from "../../components/cancellation/CancellationSecondStep.vue";
import PlanService from "../../api/services/plan.js";

export default {
  components: {
    CancellationFirstStep,
    CancellationSecondStep,
  },

  data: () => {
    return {
      step: 0,
      cancellationDate: "",
      reason: "",
      feedbackExperience: "",
      feedbackMessage: "",
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    next: function () {
      this.step = this.step + 1;
    },

    selectedReason: function (value) {
      this.reason = value;
    },

    getCancellationDate: function (value) {
      this.cancellationDate = value;
    },

    getFeedbackExperience: function (value) {
      this.feedbackExperience = value;
    },

    getFeedbackMessage: function (value) {
      this.feedbackMessage = value;
    },

    async cancelPlan() {
      try {
        const params = {
          feedback_experience: this.feedbackExperience,
          feedback_message: this.feedbackMessage,
          cancellation_reason: this.reason,
          cancellation_date: this.cancellationDate,
        };
        await PlanService.cancelPlan(this.$route.params.id, params);
        this.$router.push("/plans");
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 422:
            this.notify({
              category: "simple",
              type: "error",
              title: "Something went wrong",
              text: error.response.data.error,
            });
            break;
        }
      }
    },

    closeModal: function () {
      this.$router.push("/plans");
    },
  },
};
</script>
