<template>
  <div class="mt-8 flex relative flex-col-reverse flex-grow">
    <div class="relative rounded-3xl transform-gpu flex flex-col gap-2">
      <div class="flex flex-col">
        <div class="mt-2">
          <ul class="space-y-4">
            <li
              v-for="(bulletPoint, index) in marketing.bulletPoints"
              v-bind:key="index"
            >
              <div class="flex">
                <div class="mr-6 mt-2">
                  <div class="bg-primary-light rounded-full p-2 inline-block">
                    <component
                      :is="bulletPoint.icon"
                      class="w-6 h-6 text-black"
                    />
                  </div>
                </div>
                <div>
                  <div class="bg-secondary p-2 rounded-full font-bold font-heading-1 text-2xl inline-block" style="transform: rotate(-2.0002deg)">
                    <p class="font-bold text-lg">{{ bulletPoint.title }}</p>
                  </div>
                  <p class="mt-2 text-gray-500">{{ bulletPoint.text }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BookOpenIcon,
  CurrencyEuroIcon,
  TrendingDownIcon,
  ChartPieIcon,
  CursorClickIcon,
  ClockIcon,
  LockOpenIcon,
} from "@heroicons/vue/solid";

export default {
  components: {
    BookOpenIcon,
    CurrencyEuroIcon,
    TrendingDownIcon,
    ChartPieIcon,
    CursorClickIcon,
    ClockIcon,
    LockOpenIcon,
  },

  props: {
    marketing: Object,
  },
};
</script>
