import { plainAxiosInstance, securedAxiosInstance } from "../axios";
import { SIGNIN_URL, SIGNUP_URL } from "./constants";

class AuthService {
  signin(user) {
    return plainAxiosInstance.post(SIGNIN_URL, {
      ...user,
    });
  }

  signup(params) {
    return plainAxiosInstance.post(SIGNUP_URL, params);
  }

  signout() {
    return securedAxiosInstance.delete(SIGNIN_URL);
  }
}

export default new AuthService();
