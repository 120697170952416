import CustomerService from "../../api/services/customer";

const initialState = {
  isLoading: false,
  plans: [],
};

export default {
  namespaced: true,

  state: initialState,

  mutations: {
    setPlans(state, plans) {
      state.plans = plans;
    },

    setIsLoading(state, val) {
      state.isLoading = val;
    },

    resetState(state) {
      state.isLoading = initialState.isLoading;
      state.plans = initialState.plans;
    },
  },

  actions: {
    reinitializeCustomerState({ commit }) {
      commit("resetState");
    },

    async getPlans({ commit }) {
      try {
        commit("setIsLoading", true);

        const response = await CustomerService.getPlans();
        const { plans } = {
          ...response.data,
        };

        commit("setPlans", plans);
        commit("setIsLoading", false);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
