<template>
  <div>
    <div>
      <legend class="text-lg font-medium text-gray-900">
        {{ this.getQuestion }}
      </legend>
      <div class="mt-4">
        <textarea
          name="comment"
          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
          v-model="customerInputText"
          rows="5"
          :id="`textarea-${this.question.id}`"
        >
        </textarea>
      </div>
      <p v-if="!this.validResponse" class="my-2 text-sm text-red-600">
        {{ $t("You must fill the text area") }}
      </p>
    </div>
    <FormNavigationButton
      :isFirstStep="this.isFirstStep"
      @next="next"
      @previous="previous"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormNavigationButton from "@/views/customer/claim/form/navigation/FormNavigationButton";
import { getTranslatedQuestionIfNeeded } from "@/views/customer/claim/form/utils/translation-functions";

export default {
  components: {
    FormNavigationButton,
  },

  props: ["question", "possibleResponses", "isFirstStep", "currentResponse"],

  computed: {
    isValid() {
      return this.customerInputText != null && this.customerInputText !== "";
    },
    getSingleResponseId() {
      return this.possibleResponses[0].id;
    },
    getQuestion() {
      return getTranslatedQuestionIfNeeded(this.question, this.$i18n.locale);
    },
  },

  data() {
    return {
      customerInputText: this.currentResponse,
      validResponse: true,
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    next: function () {
      if (this.isValid) {
        this.validResponse = true;
        this.$emit("next", this.customerInputText, this.getSingleResponseId);
      } else {
        this.validResponse = false;
      }
    },

    previous: function () {
      this.$emit("previous");
    },
  },
};
</script>
