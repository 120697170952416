<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <div>
      <h1
        class="text-xl pb-4 pl-2 font-extrabold tracking-tight text-gray-900"
      >
        {{ $t("Past claims") }}
      </h1>
    </div>
    <div v-if="this.claims.length !== 0">
      <div class="flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr class="hidden md:table-row">
                    <th
                      scope="col"
                      class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
                    >
                      {{ $t("Claim Id") }}
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Nom du produit
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Date de déclaration
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr
                    v-for="(claim, id) in claims"
                    v-bind:key="id"
                    @click="redirectToClaimDetailsPage(claim.friendlyId)"
                    v-bind:class="[
                      getBorderStyle(claim.requiredAction),
                      'cursor-pointer hover:bg-gray-100',
                    ]"
                  >
                    <td
                      v-bind:class="[
                        'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
                      ]"
                    >
                      <div class="flex flex-col">
                        <div class="flex items-center">
                          <span>{{ claim.friendlyId }}</span>
                        </div>
                        <div class="md:hidden font-light">
                          {{ this.getFormatedDate(claim.filedDate) }}
                        </div>
                        <div class="md:hidden font-light">
                          {{ claim.productTitle }}
                        </div>
                        <div class="mt-2">
                          <span
                            v-bind:class="[
                              getClaimStatusBannerStyle(claim.status.color),
                              'inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium',
                            ]"
                          >
                            {{ claim.status.text }}
                          </span>
                          <span
                            v-if="claim.requiredAction"
                            class="inline-flex items-center px-3 py-0.5 ml-3 rounded-full text-xs font-medium bg-red-50 text-black"
                          >
                            Une action est requise de votre part
                          </span>
                        </div>
                      </div>
                    </td>
                    <td
                      class="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ claim.productTitle }}
                    </td>
                    <td
                      class="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ this.getFormatedDate(claim.filedDate) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.claims.length === 0" class="mt-4">
      <svg
        class="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vector-effect="non-scaling-stroke"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        ></path>
      </svg>
      <h1
        class="mt-1 text-sm text-gray-500"
        v-bind:style="{ 'text-align': 'center' }"
      >
        {{ $t("No claims yet") }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: ["claims"],

  methods: {
    getClaimStatusBannerStyle: function (color) {
      return `bg-${color}-100 text-${color}-800 bg-yellow-50`;
    },

    redirectToClaimDetailsPage: function (friendlyId) {
      this.$router.push(`/customer/claims/${friendlyId}`);
    },

    getFormatedDate: function (dateToFormat) {
      const currentLocale = this.$i18n.locale;
      return dateToFormat !== null
        ? new Date(dateToFormat).toLocaleDateString(currentLocale)
        : null;
    },

    getBorderStyle: function (isActionRequired) {
      return isActionRequired
        ? "border-l-2 border-yellow-400 bg-yellow-50"
        : "";
    },
  },
};
</script>
