import { securedAxiosInstance } from "../axios";

class PlanService {
  cancelPlan(planSaleId, params) {
    return securedAxiosInstance.post(
      `/customer/plan_sales/${planSaleId}/cancel`,
      params
    );
  }

  deleteProductItem(planSaleId, productItemId) {
    return securedAxiosInstance.delete(
      `/customer/plan_sales/${planSaleId}/composed_plan_sale_product_items/${productItemId}`
    );
  }

  addProductItem(planSaleId, formData) {
    return securedAxiosInstance.post(
      `/customer/plan_sales/${planSaleId}/composed_plan_sale_product_items`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  updateProductItem(planSaleId, productItemId, formData) {
    return securedAxiosInstance.put(
      `/customer/plan_sales/${planSaleId}/composed_plan_sale_product_items/${productItemId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  getCancellationReasons() {
    return securedAxiosInstance.get("/customer/cancellation_reasons");
  }
}

export default new PlanService();
