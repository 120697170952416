<template>
  <div>
    <Notifications />
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
const defaultLayout = "public-layout";
import PublicLayout from "./layouts/PublicLayout.vue";
import CustomerLayout from "./layouts/CustomerLayout.vue";
import Notifications from "./components/Notifications";
import { mapActions } from "vuex";

export default {
  components: {
    PublicLayout,
    CustomerLayout,
    Notifications,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || defaultLayout;
    },
  },
  mounted() {
    this.clearAllNotifications();
    const APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/" + APP_ID;
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();
  },

  methods: {
    ...mapActions("notifications", ["clearAll"]),

    clearAllNotifications() {
      this.clearAll();
    },
  },
};
</script>
