export function getTranslatedQuestionIfNeeded(question, currentLocale) {
  return currentLocale === "fr"
    ? question.value
    : question.__translation[currentLocale];
}

export function getTranslatedResponseIfNeeded(response, currentLocale) {
  return currentLocale === "fr"
    ? response.description
    : response.__translation[currentLocale];
}
