<template>
  <div>
    <h1 class="text-xl pb-4 pl-2 font-extrabold tracking-tight text-gray-900">
      {{ $t("Documents") }}
    </h1>
    <ul role="list" class="divide-y divide-gray-100">
      <li class="py-2 hover:bg-gray-200 cursor-pointer">
        <div
          class="flex gap-x-4"
          @click="this.openLink(this.plan.informationNotice)"
        >
          <DocumentTextIcon
            class="h-12 w-12 flex-none rounded-full text-primary-dark"
          />
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">
              Notice d'information
            </p>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              Un document utile détaillant l'ensemble de vos garanties
            </p>
          </div>
        </div>
      </li>
      <li class="py-2 hover:bg-gray-200 cursor-pointer">
        <div class="flex gap-x-4" @click="this.openLink(this.plan.ipidFile)">
          <DocumentTextIcon
            class="h-12 w-12 flex-none rounded-full text-primary-dark"
          />
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">
              Fiche d'information produit
            </p>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              Le contenu de votre contrat
            </p>
          </div>
        </div>
      </li>
      <li class="py-2 hover:bg-gray-200 cursor-pointer">
        <div class="flex gap-x-4" @click="this.downloadMembershipAgreement">
          <DocumentTextIcon
            class="h-12 w-12 flex-none rounded-full text-primary-dark"
          />
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">
              Notice d'adhésion
            </p>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              La preuve que vous êtes assurés par Estaly
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { DocumentTextIcon } from "@heroicons/vue/solid";
import CustomerService from "@/api/services/customer";

export default {
  props: ["plan"],

  components: {
    DocumentTextIcon,
  },

  methods: {
    downloadMembershipAgreement: async function () {
      await CustomerService.getPlanMembershipAgreement(this.plan.id)
        .then((response) => {
          const url = response.data.file_url;
          this.openLink(url);
        })
        .catch((error) => {
          // Handle any errors
          console.error(error);
        });
    },

    openLink: function (link) {
      window.open(link, "_blank");
    },
  },
};
</script>
