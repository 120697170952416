<template>
  <TransitionRoot appear v-bind:show="this.isOpen" as="template">
    <Dialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full lg:ml-20 max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative"
            >
              <div class="absolute top-4 right-4">
                <XIcon class="w-6 h-6 cursor-pointer" @click="closeModal" />
              </div>

              <div class="flex justify-between">
                <DialogTitle as="h1" class="text-gray-900">
                  <span class="font-bold">
                    Modifier le produit
                  </span>
                </DialogTitle>
              </div>

              <VeeForm
                v-slot="{ handleSubmit }"
                :validation-schema="schema"
                as="div"
              >
                <form
                  class="space-y-4"
                  @submit="handleSubmit($event, handleUpdateProductItem)"
                  ref="UpdateItemForm"
                >
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                  <div>
                    <label
                      for="itemTitle"
                      class="block text-sm text-gray-700"
                      >Nom du produit
                      <span class="ml-1 text-red-600">*</span></label
                    >
                    <div class="mt-2">
                      <Field
                        id="itemTitle"
                        name="itemTitle"
                        type="text"
                        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Nom du produit"
                        v-model="this.itemTitle"
                      />
                      <ErrorMessage
                        name="itemTitle"
                        class="mt-2 text-sm text-red-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label for="itemPrice" class="block text-sm text-gray-700">Prix du produit <span class="ml-1 text-red-600">*</span></label>
                    <div class="mt-2 relative">
                      <Field
                        id="itemPrice"
                        name="itemPrice"
                        type="text"
                        class="pr-16 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="249,99"
                        v-model="itemPriceFormatted"
                      />
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
                      </div>
                      <ErrorMessage name="itemPrice" class="mt-2 text-sm text-red-600" />
                    </div>
                  </div>
                  <div>
                    <label for="itemType" class="block text-sm text-gray-700">Type du produit <span class="ml-1 text-red-600">*</span></label>
                    <div class="mt-2">
                      <Field
                        as="select"
                        id="itemType"
                        name="itemType"
                        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        v-model="itemType"
                      >
                        <option disabled value="">Veuillez sélectionner un type</option>
                        <option v-for="type in plan.itemTypes" :key="type" :value="type">{{ $t(type) }}</option>
                      </Field>
                      <ErrorMessage name="itemType" class="mt-2 text-sm text-red-600" />
                    </div>
                  </div>
                  <div>
                    <label
                      for="itemReferenceId"
                      class="block text-sm text-gray-700"
                      >Numéro de série ou IMEI
                      <span class="ml-1 text-red-600">*</span></label
                    >
                    <div class="mt-2">
                      <Field
                        id="itemReferenceId"
                        name="itemReferenceId"
                        type="text"
                        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="35478405412998702"
                        v-model="this.itemReferenceId"
                      />
                      <ErrorMessage
                        name="itemReferenceId"
                        class="mt-2 text-sm text-red-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="itemVendor"
                      class="block text-sm text-gray-700"
                      >Marque
                      <span class="ml-1 text-red-600">*</span></label
                    >
                    <div class="mt-2">
                      <Field
                        id="itemVendor"
                        name="itemVendor"
                        type="text"
                        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Ex : Samsung, Apple, Sony..."
                        v-model="this.itemVendor"
                      />
                      <ErrorMessage
                        name="itemVendor"
                        class="mt-2 text-sm text-red-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="itemModel"
                      class="block text-sm text-gray-700"
                      >Modèle
                      <span class="ml-1 text-red-600">*</span></label
                    >
                    <div class="mt-2">
                      <Field
                        id="itemModel"
                        name="itemModel"
                        type="text"
                        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Ex : Series 6, Q80T, Hero9, PlayStation 5..."
                        v-model="this.itemModel"
                      />
                      <ErrorMessage
                        name="itemModel"
                        class="mt-2 text-sm text-red-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label 
                      for="itemPurchaseDate" 
                      class="block text-sm text-gray-700"
                      >Date d'achat
                      <span class="ml-1 text-red-600">*</span
                      ></label
                    >
                    <div class="mt-2 relative">
                      <Field
                        type="date"
                        name="itemPurchaseDate"
                        id="itemPurchaseDate"
                        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        v-model="itemPurchaseDate"
                      />
                      <ErrorMessage
                        name="itemPurchaseDate"
                        class="mt-2 text-sm text-red-600"
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-6">
                  <div class="flex flex-row gap-4">
                    <div class="sm:w-1/2">
                      <button
                        class="w-full border border-transparent px-4 py-2 text-lg font-medium transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 text-button-font-primary bg-primary uppercase"
                        type="submit"
                      >
                        {{ $t("Modifier") }}
                      </button>
                    </div>

                    <div class="sm:w-1/2">
                      <button
                        class="w-full border border-transparent bg-red-100 px-4 py-2 text-lg font-medium text-red-700 transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                        type="button"
                        @click="closeModal"
                      >
                        {{ $t("Close") }}
                      </button>
                    </div>
                  </div>
                </div>
                </form>
              </VeeForm>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import PlanService from "@/api/services/plan.js";
import { mapActions } from "vuex";
import { ErrorMessage, Field, Form as VeeForm } from "vee-validate";
import * as yup from "yup";

export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    ErrorMessage,
    Field,
    VeeForm,
  },

  props: [
    "isOpen",
    "plan",
    "productItem",
  ],

  data: () => {
    const schema = yup.object({
      itemTitle: yup
        .string()
        .required("Le nom du produit est requis"),
      itemReferenceId: yup
        .string()
        .required("Le numéro de série est requis"),
      itemVendor: yup
        .string()
        .required("La marque du produit est requise"),
      itemModel: yup
        .string()
        .required("Le modèle du produit est requis"),
      itemPurchaseDate: yup
        .date()
        .nullable()
        .transform((value, originalValue) => originalValue === '' ? null : value)
        .max(new Date(), "La date d'achat ne peut pas être dans le futur")
        .min(new Date(new Date().setFullYear(new Date().getFullYear() - 3)), "La date d'achat ne peut pas être supérieure à 3 ans")
        .required("La date d'achat est requise")
        .typeError("La date d'achat est invalide"),
      itemPrice: yup.string()
        .required("Le prix du produit est requis.")
        .matches(
          /^\d+(\.\d{1,2})?$|^\d+(,\d{1,2})?$/, 
          "Le format du prix est invalide."
        ),
       itemType: yup.string().required("Le type du produit est requis"),
    });
    return {
      schema,
      updateSuccess: false,
      itemTitle: "",
      itemPrice: "",
      itemType: "",
      itemReferenceId: "",
      itemVendor: "",
      itemModel: "",
      itemPurchaseDate: "",
    };
  },

  computed: {
    itemPriceFormatted: {
      get() {
        return this.itemPrice === undefined ? '' : this.itemPrice.toString().replace('.', ',');
      },
      set(value) {
        this.itemPrice = value.replace(',', '.');
      }
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    closeModal: function () {
      this.$emit("closeUpdateProductItemModal", this.updateSuccess);
    },

    refreshPlanSale: function() {
      this.$emit("refreshPlanSale");
    },

    handleUpdateProductItem: async function () {
      try {
        const formData = new FormData();

        formData.append('title', this.itemTitle);
        formData.append('price', this.itemPrice);
        formData.append('item_type', this.itemType);
        formData.append('reference_id', this.itemReferenceId);
        formData.append('vendor', this.itemVendor);
        formData.append('model', this.itemModel);
        formData.append('purchase_date', this.itemPurchaseDate);
        formData.append('item_image', this.itemImage);
        formData.append('product_category_id', this.plan.productCategoryId);

        await PlanService.updateProductItem(this.plan.id, this.productItem.id, formData);
        this.notify({
          category: "simple",
          type: "success",
          title: "Le produit a été modifié",
        });
        this.updateSuccess = true;
        this.closeModal();
        this.refreshPlanSale();
      } catch (error) {
        console.error(error);
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite",
          text: error.response ? error.response.data.error : "Une erreur est survenue lors de la soumission.",
        });
      }
    },
  },

  watch: {
    productItem: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.itemTitle = newValue.title || "";
          this.itemPrice = newValue.price.toString().replace('.', ',') || "";
          this.itemType = newValue.itemType || "";
          this.itemReferenceId = newValue.referenceId || "";
          this.itemVendor = newValue.vendor || "";
          this.itemModel = newValue.model || "";
          if (newValue.purchaseDate) {
            const parts = newValue.purchaseDate.split('/');
            if (parts.length === 3) {
              this.itemPurchaseDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
            } else {
              this.itemPurchaseDate = '';
            }
          } else {
            this.itemPurchaseDate = '';
          }
        }
      },
      immediate: true
    }
  },
};
</script>