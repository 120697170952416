import { securedAxiosInstance } from "../axios";
import { CUSTOMER_PLAN_SALES_URL } from "./constants";

class CustomerService {
  getPlans() {
    return securedAxiosInstance.get(CUSTOMER_PLAN_SALES_URL);
  }

  getClaimsByPlanSaleId(planSaleId) {
    return securedAxiosInstance.get(
      `/customer/plan_sales/${planSaleId}/claims`
    );
  }

  getPlanMembershipAgreement(planSaleId) {
    return securedAxiosInstance.get(
      `/customer/plan_sales/${planSaleId}/membership_agreement`
    );
  }

  getClaims() {
    return securedAxiosInstance.get("/customer/claims");
  }

  getClaimByFriendlyId(claimId) {
    return securedAxiosInstance.get(`/customer/claims/${claimId}`);
  }

  sendComplaint(message) {
    const data = {
      complaint: message,
    };
    return securedAxiosInstance.post(`/customer/complaints`, data);
  }

  getPlanSaleBillings(page) {
    return securedAxiosInstance.get(`/customer/plan_sale_billings?page=${page}`);
  }

  getPlanSaleBillingInvoice(planSaleBillingId) {
    return securedAxiosInstance.get(`/customer/plan_sale_billings/${planSaleBillingId}`);
  }
}

export default new CustomerService();
