import { isStringValid } from "@/utils/validation";

export function formatPrice(locale, price, currencyCode) {
  const defaultCurrencyCode = "EUR";
  let options = {
    style: "currency",
    currency: isStringValid(currencyCode) ? currencyCode : defaultCurrencyCode,
    maximumFractionDigits: price % 1 === 0 ? 0 : 2,
  };
  return new Intl.NumberFormat(locale, options).format(price);
}
