<template>
  <TransitionRoot appear v-bind:show="this.isOpen" as="template">
    <Dialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full lg:ml-20 max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative"
            >
              <div class="absolute top-4 right-4">
                <XIcon class="w-6 h-6 cursor-pointer" @click="closeModal" />
              </div>

              <div class="flex justify-between">
                <DialogTitle as="h1" class="text-gray-900">
                  <span class="font-bold">
                    Etes vous sûr de vouloir supprimer le produit {{ productItem?.title }} de votre formule ? 
                  </span>
                </DialogTitle>
              </div>
              <div>
              <p class="text-md"></p>
              </div>
              <div class="mt-6">
                <div class="flex flex-row gap-4">
                  <div class="sm:w-1/2">
                    <button
                      class="w-full border border-transparent px-4 py-2 text-lg font-medium transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 text-button-font-primary bg-primary uppercase"
                      type="submit"
                      @click="deleteProductItem"
                    >
                      {{ $t("Supprimer") }}
                    </button>
                  </div>

                  <div class="sm:w-1/2">
                    <button
                      class="w-full border border-transparent bg-red-100 px-4 py-2 text-lg font-medium text-red-700 transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      @click="closeModal"
                    >
                      {{ $t("Close") }}
                    </button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import PlanService from "@/api/services/plan.js";
import { mapActions } from "vuex";

export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },

  props: [
    "isOpen",
    "productItem",
    "planSaleId",
  ],

  data: () => {
    return {
      deleteSuccess: false,
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    closeModal: function () {
      this.$emit("closeDeleteProductItemModal", this.deleteSuccess);
    },

    refreshPlanSale: function() {
      this.$emit("refreshPlanSale");
    },

    deleteProductItem: async function () {
      try {
        await PlanService.deleteProductItem(this.planSaleId, this.productItem.id)
        this.notify({
          category: "simple",
          type: "success",
          title: "Le produit a été supprimé",
        });
      } catch (error) {
        console.log(error);
        const statusCode = error.response.status;
        switch (statusCode) {
          case 422:
            this.notify({
              category: "simple",
              type: "error",
              title: "Une erreur s'est produite",
              text: error.response.data.error,
            });
            break;
          default:
            this.notify({
              category: "simple",
              type: "error",
              title: "Une erreur s'est produite",
              text: "Nos équipes ont été notifiées et se chargent de résoudre ce problème le plus rapidement possible",
            });
        }
      } finally {
        this.deleteSuccess = true;
        this.closeModal();
        this.refreshPlanSale();
      }
    },
  },
};
</script>