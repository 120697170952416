<template>
  <div>
    <fieldset>
      <legend class="text-lg font-medium text-gray-900">
        {{ this.getQuestion }}
      </legend>
      <div
        class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
      >
        <div
          v-for="response in this.possibleResponses"
          :key="response.id"
          class="relative flex items-start py-4"
        >
          <div class="min-w-0 flex-1 text-sm">
            <label
              :for="`checkbox-${response.id}`"
              class="font-medium text-gray-700 select-none cursor-pointer block w-full"
              >{{ this.getResponse(response) }}</label
            >
          </div>
          <div class="ml-3 flex items-center h-5">
            <input
              :id="`checkbox-${response.id}`"
              name="plan"
              type="radio"
              :value="response.id"
              v-model="this.responseId"
              class="cursor-pointer focus:ring-primary h-4 w-4 text-indigo-600 border-gray-300"
            />
          </div>
        </div>
      </div>
    </fieldset>
    <p v-if="!this.validResponse" class="my-2 text-sm text-red-600">
      {{ $t("You must check at least one response") }}
    </p>
    <FormNavigationButton
      :isFirstStep="this.isFirstStep"
      @next="next"
      @previous="previous"
    />
  </div>
</template>

<script>
import FormNavigationButton from "@/views/customer/claim/form/navigation/FormNavigationButton";
import {
  getTranslatedQuestionIfNeeded,
  getTranslatedResponseIfNeeded,
} from "@/views/customer/claim/form/utils/translation-functions";

export default {
  components: {
    FormNavigationButton,
  },

  props: ["question", "possibleResponses", "isFirstStep", "currentResponse"],

  computed: {
    isValid() {
      return this.responseId != null;
    },
    getQuestion() {
      return getTranslatedQuestionIfNeeded(this.question, this.$i18n.locale);
    },
  },

  methods: {
    next: function () {
      if (this.isValid) {
        this.validResponse = true;
        const response = {
          content: this.getResponseDescription(this.responseId),
          id: this.responseId,
        };
        this.$emit("next", response, this.responseId);
      } else {
        this.validResponse = false;
      }
    },

    previous: function () {
      this.$emit("previous");
    },

    getResponseDescription: function (responseId) {
      return responseId !== null
        ? this.possibleResponses.find(
            (response) => response.id === this.responseId
          ).description
        : null;
    },

    getResponse: function (response) {
      return getTranslatedResponseIfNeeded(response, this.$i18n.locale);
    },
  },

  data() {
    return {
      responseId:
        this.currentResponse !== null ? this.currentResponse.id : null,
      validResponse: true,
    };
  },
};
</script>
